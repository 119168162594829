<template>
  <router-view></router-view>
</template>

<script setup>
import { onBeforeMount } from 'vue';


onBeforeMount(() => {
	let imgs = [
    //用require的方式添加图片地址，直接添加图片地址的话，在build打包之后会查找不到图片，因为打包之后的图片名称会有一个加密的字符串
        require('@/assets/home-bacc.png'),
        require('@/assets/tips.svg'),
        require('@/assets/close.svg'),
        require('@/assets/知识库建议背景图.jpg'),
    ]
	for(let img of imgs ){
		let image = new Image();
		image.src = img;
	}
})


</script>

<style lang="less">
/* @font-face {
  font-family: "LXGWWenKai";
  src: url("@/assets/LXGWWenKaiScreenR.ttf");
} */

#app{
	font: normal 18px/1.5 Helvetica, Arial, sans-serif;
	color: #fff;
}

@media (max-width: 991px) {
	#app{
		font-size: 16px;
	}
}

@media (max-width: 768px) {
	#app{
		font-size: 14px;
	}
}

@media (max-width: 576px) {
	#app{
		font-size: 12px;
	}
}

</style>