import { createWebHashHistory, createRouter } from 'vue-router'
const routes = [
    {
        path: '/',
        component: () => import('@/views/Home.vue')
    },
    {
        path: '/knowledge',
        component: () => import('@/views/Knowledge/Index.vue'),
        children: [
            {
                path: 'hr',
                component: () => import('@/views/Knowledge/Hr.vue')
            },
            {
                path: 'it',
                component: () => import('@/views/Knowledge/It.vue')
            },
            {
                path: 'finance',
                component: () => import('@/views/Knowledge/Finance.vue')
            },
            {
                path: 'quotation',
                component: () => import('@/views/Knowledge/Quotation.vue')
            },
            {
                path: 'game',
                component: () => import('@/views/Knowledge/Game.vue')
            },
            {
                path: 'token',
                component: () => import('@/views/Knowledge/Token.vue')
            }
        ]
    }
]

const router = createRouter({
    history: createWebHashHistory(),
    routes
})

export default router