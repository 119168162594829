import { createApp } from 'vue'
import App from './App.vue'
import antd from 'ant-design-vue'
import { Quasar } from 'quasar'
import 'quasar/dist/quasar.css'
import router from '@/router/router.js'

const app = createApp(App)
app.use(antd).use(router).use(Quasar).mount('#app')


// router.beforeEach((to, from, next) => {
//     if(to.path === '/')
//         next('/hr');
//     else
//         next();
// });